//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { LocalStorage } from 'quasar'
import common from './common/common'
import api from './networking/api'
    export default {
        name: 'FileList',
        data() {
            return {
                ossPath:api.imageHost + "/",
                keywords: "",
                mediaList: [],
                catalogueList:[],
                allFolderlist:[],//所有目录
                folderList:[],//目录树
                allFolderList:[],
                folderListFiltered:[],//当前目录目录树
                folderDirs:[],//目录路径
                currentFolderDirs:[],//当前素材目录路径
                loading:false,
                showShare: false,//分享弹框
                shareItem:{},//分享项
                showFolderPop:true,
                options: [
                    // { name: '立即发送', icon: require('../../../assets/share.png') },
                    // { name: '分享链接', icon: 'link' },
                    // { name: '素材预览', icon: 'poster' },
                ],
            };
        },
        created() {
            let that = this;
            console.log(this.$route,'route');
            that.getFolderList();
            
            that.requestJsSdkConfig()
            let appName = LocalStorage.getItem("appName")
            if(appName){
                this.getAppTitle(appName)
            }else{
                window.document.title = "素材中心";
            }
        },
        methods: {
            getAppTitle(appName){
                const that = this;
                this.axios
                .get( "/" + this.$ajaxPrefix.consumer +"/app/getByAppName?appName=" + appName)
                .then((res) => {
                    console.log(res);
                    if(res.data && res.data.data){
                        window.document.title = res.data.data.introduce || "素材中心"
                    }
                });
            },
            requestJsSdkConfig: function() {
                let that = this;
                // let agentId = common.config.agentId;
                // let corpId = common.config.corpId;
                let agentId = LocalStorage.getItem("agentId");
                let corpId = LocalStorage.getItem("corpId");
                this.axios.get(api.getJsSdkConfig, {
                    params: {
                    agentId,
                    corpId,
                    url: encodeURIComponent(window.location.href.split('#'))
                    }
                })
                    .then(function (response) {
                    console.log(response.data.data);
                    if (common.success(response)) {
                        let data = response.data.data;
                        that.wxConfigData = {nonceStr: data.nonceStr, timestamp: data.timestamp, signature: data.signature, appId: data.appId};
                        that.wxConfig(that.wxConfigData)
                    } else {
                        // alert('获取JsSdkConfig失败：' + response.data.reason);
                    }
                    })
                    .catch(function (error) {
                    console.log(error);
                    // alert('获取JsSdkConfig失败：' + error);
                    })
                    .then(function () {

                    });
            },
            wxConfig: function(data) {
                let that = this
                wx.config({
                    beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: data.appId, // 必填，企业微信的corpID
                    timestamp: data.timestamp, //  必填，生成签名的时间戳
                    nonceStr: data.nonceStr, //  必填，生成签名的随机串
                    signature: data.signature,// 必填，签名，见 附录-JS-SDK使用权限签名算法
                    jsApiList: ['agentConfig', 'onMenuShareWechat', 'shareWechatMessage', "sendChatMessage",'getContext','previewImage','previewFile'] // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
                });
                
                wx.ready(function () {
                    console.log('ready');
                    // let agentId = common.config.agentId;
                    // let corpId = common.config.corpId;
                    let agentId = LocalStorage.getItem("agentId");
                    let corpId = LocalStorage.getItem("corpId");
                    that.axios.get(api.getAgentJsSdkConfig, {
                        params: {
                        agentId,
                        corpId,
                        url: encodeURIComponent(window.location.href.split('#'))
                        }
                    })
                    .then(function (response) {
                        console.log(response,"AgentJsSdkConfig");
                        if (common.success(response)) {
                            
                            let data = response.data.data;
                            
                            wx.agentConfig({
                                corpid: corpId, // 必填，企业微信的corpid，必须与当前登录的企业一致
                                agentid: agentId, // 必填，企业微信的应用id （e.g. 1000247）
                                timestamp: data.timestamp, //  必填，生成签名的时间戳
                                nonceStr: data.nonceStr, //  必填，生成签名的随机串
                                signature: data.signature,// 必填，签名，见 附录-JS-SDK使用权限签名算法
                                jsApiList: [ 'agentConfig', 'onMenuShareWechat', 'shareWechatMessage', "sendChatMessage",'getContext','previewImage','previewFile'], // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
                                success: function(res) {
                                    // 回调
                                    console.log(res,"agentConfig");
                                 
                                },
                                fail: function(res) {
                                    console.log(res);
                                    if(res.errMsg.indexOf('function not exist') > -1){
                                        alert('版本过低请升级')
                                    }
                                }
                            });
                            
                        } else {
                            // alert('获取AgentJsSdkConfig失败：' + response.data.reason);
                        }
                    })
                    .catch(function (error) {
                    console.log(error);
                    // alert('获取JsSdkConfig失败：' + error);
                    })
                    .then(function () {

                    });
                   
                    
                    // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
                });

                wx.error(function (res) {
                    console.log(res);
                    // alert(JSON.stringify(res));
                    // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
                });
            },
            onSelect(option) {
                const that = this;
                console.log(option.name);
                let shareItem = this.shareItem;
                console.log(shareItem);
                if(option.name == "立即发送"){
                    //  this.$toast({message: option.name + 'sendChatMessage', duration: 1500});
                      this.loading = true;
                     this.axios
                        .get("/media/wxcp/media/share/image/info/getMediaId?id=" + shareItem.id)
                        .then((res) => {
                            console.log(res);
                              this.loading = false;
                        if ( res.data &&  res.data.data  )  {
                            let sendData = {}
                            if(shareItem.mediaType == 1 || shareItem.mediaType == 3){
                                sendData.msgtype = "image"
                                sendData.image = {
                                    mediaid: res.data.data.mediaId
                                } 
                            }else if(shareItem.mediaType == 2){
                                sendData.msgtype = "video"
                                sendData.video = {
                                    mediaid:res.data.data.mediaId
                                } 
                            }else{
                                sendData.msgtype = "file"
                                sendData.file = {
                                    mediaid:res.data.data.mediaId
                                } 
                            } 
                            console.log(sendData);
                            //消息格式支持文本(“text”)，图片(“image”)，视频(“video”)，文件(“file”)，H5(“news”）和小程序(“miniprogram”)
                            wx.invoke('sendChatMessage',sendData , function(res) {
                                console.log(res,"sendChatMessage");
                                if (res.err_msg == 'sendChatMessage:ok') {
                                    //发送成功
                                }
                            })
                        }
                        });
                       
                    
                }else if(option.name == "素材预览"){
                    if(shareItem.mediaType == 2 || shareItem.mediaType == 6){
                        wx.previewFile({
                            url: shareItem.path, // 需要预览文件的地址(必填，可以使用相对路径)
                            size: shareItem.ext.size // 需要预览文件的字节大小(必填，而且大小必须正确，否则会打开失败)
                        });
                        return
                    }
                    else if(shareItem.mediaType == 1 || shareItem.mediaType == 3){
                        let urls = [];
                        that.mediaList.map(v =>{
                            if(v.mediaType == 1 || v.mediaType == 3){
                                urls.push(v.path)
                            }
                        })
                        wx.previewImage({
                        current: shareItem.path, // 当前显示图片的http链接
                            urls: urls, // 需要预览的图片http链接列表
                            success: function (res) {
                                console.log(res,'previewImage');
                            }
                        });
                    }
                }
                else{
                    // this.$toast({message: option.name + 'shareAppMessage', duration: 1500});
                    wx.invoke(
                        "shareAppMessage", {
                            title: shareItem.title, // 分享标题
                            desc: shareItem.content, // 分享描述
                            link: shareItem.path, // 分享链接
                            imgUrl: shareItem.path, // 分享图标
                        }, function(res) {
                            if (res.err_msg == "shareAppMessage:ok") {
                            }
                        }
                    );
                    
                }
            },
            showSharePop(item){
                console.log(item);
                let _this = this;
                this.shareItem = item;
               
                this.options = []
                _this.options.unshift({ name: '分享链接', icon: 'link' })
                // if(item.mediaType == 1 || item.mediaType == 2 || item.mediaType == 3){
                    this.options.unshift({ name: '素材预览', icon: 'poster' })
                // }
                   console.log(this.options,'options');
                wx.invoke('getContext', {
                }, function(res){
                    console.log(res,'getContext');
                if(res.err_msg == "getContext:ok"){
                    //res.entry ; //返回进入H5页面的入口类型，目前有normal、contact_profile、single_chat_tools、group_chat_tools、chat_attachment
                   
                   if(res.entry.indexOf('chat') > -1){
                       _this.options.unshift({ name: '立即发送', icon:  require('../../../assets/share.png') })
                        _this.showShare = true
                    }else{
                        if(item.mediaType == 2 || item.mediaType == 6){
                            if(/Android|webOS|iPhone|iPod|iPad|BlackBerry/i.test(navigator.userAgent)){
                                wx.previewFile({
                                    url: item.path, // 需要预览文件的地址(必填，可以使用相对路径)
                                    size: item.ext.size // 需要预览文件的字节大小(必填，而且大小必须正确，否则会打开失败)
                                });
                                return
                            }else{
                                window.open(item.path)
                                return
                            }
                        }
                        else if(item.mediaType == 1 || item.mediaType == 3){
                            let urls = [];
                            _this.mediaList.map(v =>{
                                if(v.mediaType == 1 || v.mediaType == 3){
                                    urls.push(v.path)
                                }
                            })
                            wx.previewImage({
                            current: item.path, // 当前显示图片的http链接
                                urls: urls, // 需要预览的图片http链接列表
                                success: function (res) {
                                    console.log(res,'previewImage');
                                }
                            });
                        }
                        
                    }
                     
                }else {
                    //错误处理
                }
            });
            },
            onSearch(e){
                console.log("search",e);
                if(e == 'mediaCenter'){
                    var script = document.createElement('script');
                    script.src = 'https://cdn.bootcdn.net/ajax/libs/vConsole/3.9.0/vconsole.min.js'
                    document.getElementsByTagName('body')[0].appendChild(script)
                    script.onload = ()=>{
                        var vConsole = new VConsole();
                    }
                }
                this.getList(this.folderDirs && this.folderDirs[this.folderDirs.length -1].id)
            },
            chooseFolder(item){
                
                console.log(item);
                this.showFolderPop = true
                if(!item.id){
                    this.folderListFiltered = JSON.parse(JSON.stringify(this.folderList))
                    this.folderDirs =  []
                    this.$nextTick(()=>{
                        this.updateScroll()
                    })
                    return
                }
                this.keywords = ""
                let arr = this.allFolderList.filter(v =>{
                    return v.pid == item.id
                })
                if(arr.length == 0){
                    this.showFolderPop = false
                    this.getList(item.id)
                }else{
                    //  this.showFolderPop = false
                    //   this.getList(item.id)
                    this.folderListFiltered = arr
                }
                this.folderDirs = this.getParent(item,[])
                this.$nextTick(()=>{
                    this.updateScroll()
                })
            },
            getParent(item,dirs){
                dirs.unshift(item)
                let arr = this.allFolderList.filter(v =>{
                    return v.id == item.pid
                })
                if(arr.length > 0 ){
                    this.getParent(arr[arr.length - 1],dirs)
                }
                return dirs
            },
            changeFolder(){
                this.showFolderPop = true
               
            },
            updateScroll(){
                var trees = this.$refs.trees;
                if(trees){
                    trees.scrollLeft = trees.scrollWidth
                }
            },
            confirmFolder(){
                this.showFolderPop = false
            },
            closeFolderPop(){
                this.showFolderPop = false
            },
            /**
             * 构造树型结构数据
             * @param {*} data 数据源
             * @param {*} id id字段 默认 'id'
             * @param {*} parentId 父节点字段 默认 'parentId'
             * @param {*} children 孩子节点字段 默认 'children'
             * @param {*} rootId 根Id 默认 0
             */
            handleTree(data, id, parentId, children, rootId) {
                id = id || 'id'
                parentId = parentId || 'parentId'
                children = children || 'children'
                rootId = rootId || Math.min.apply(Math, data.map(item => { return item[parentId] })) || 0
                //对源数据深度克隆
                const cloneData = JSON.parse(JSON.stringify(data))
                const cloneData2 = JSON.parse(JSON.stringify(data))
                //循环所有项
                const treeData = cloneData.filter(father => {
                    let branchArr = cloneData.filter(child => {
                        //返回每一项的子级数组
                        return father[id] === child[parentId]
                    });
                    branchArr.length > 0 ? father.children = branchArr : '';
                    //返回第一层
                    let hasParent = true
                    cloneData2.map(item =>{
                        if(item.id == father.pid){
                            hasParent = false
                        }
                    })
                    return father[parentId] === rootId || hasParent;
                });
                return treeData != '' ? treeData : data;
            },
            getList(dirId) {
                this.loading = true;
                const that = this;
                this.axios
                    .post("/media/wxcp/media/info/list",{
                        dirId:dirId,
                        title:this.keywords
                    })
                    .then((res) => {
                        console.log(res);
                    if ( res.data && res.data.data )  {
                        
                        res.data.data.mediaList.map(v =>{
                            v.path = that.ossPath + v.path
                        })
                        that.mediaList = res.data.data.mediaList;
                        that.catalogueList = res.data.data.catalogueList
                    } else {
                        that.mediaList = [];
                    }
                    that.loading = false;
                    });
            },
            getFolderList() {
                this.loading = true;
                 this.updateScroll()
                const that = this;
                let uuid = this.$route.query.uuid || 'SnWxrZ6pCgfTOcHM';
                this.axios
                .post("/media/catalogue/listByCatalogueId",{uuid} )
                .then((res) => {
                    console.log(res);
                that.loading = false;
                if (res && res.data && res.data.data) {
                    res.data.data.map(v =>{
                        v.time = this.$moment(v.time).format("yyyy-MM-DD"),
                        v.id = parseInt(v.id)
                        v.pid = parseInt(v.pid)
                        v.children = []
                        res.data.data.map(v2 =>{
                            if(v.id == v2.pid){
                                v.hasChild = true
                            }
                        })
                    })
                    that.allFolderList = res.data.data
                    that.folderList = that.handleTree(res.data.data, "id","pid");
                    that.folderListFiltered = JSON.parse(JSON.stringify(that.folderList))
                //    that.folderDirs = [that.folderListFiltered[0]]
                } else {
                    that.allFolderList = []
                    that.folderList = [];
                    that.folderListFiltered = []
                    that.$toast.fail(res.data.msg);
                }
               
                });
            },
        }
    }
